<template>
    <div
        class="sm:mx-auto sm:w-full sm:max-w-md pt-8 content-container checkout-box radius-anchor overflow-hidden ccss--user-profile"
    >
        <h2 class="text-center text-3xl font-extrabold">
            {{ $t('auth.passwordreset.button.submit') }}
        </h2>
        <div class="sm:mx-auto sm:w-full sm:max-w-md">

            <div class="pt-8">
                <router-link :to="{ name: 'user.myaccount' }">
                    <a class="link-arrow-left">{{ $t('myaccount') }}</a>
                </router-link>
                <div class="flex mb-5 p-3 rounded-sm text-sm" :class="alert.class" v-show="alert.show">
                    <div class="flex-grow">{{ alert.message }}</div>
                </div>
                <Form @submit="onSubmit" :validation-schema="schema"><!--setFieldValue setValues -->
                    <div class="mb-3">
                        <label for="password_current" class="block text-xs"
                               :class="{'text-red-500': errors.password_current}"
                        >{{ $t('fields.password_current') }}</label>
                        <div class="mt-1">
                            <Field type="password" v-model="form.password_current" name="password_current"
                                   class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent"
                                   :class="{'error': errors.password_current}"
                            />
                        </div>
                    </div>

                    <div class="mb-3">
                        <label for="password_new" class="block text-xs"
                               :class="{'text-red-500': errors.password_new}"
                        >{{ $t('fields.password_new') }} (min: 8)</label>
                        <div class="mt-1">
                            <Field type="password" v-model="form.password_new" name="password_new"
                                   class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent"
                                   :class="{'error': errors.password_new}"
                            />
                        </div>
                    </div>

                    <div class="mb-3">
                        <label for="password_new_confirmation" class="block text-xs"
                               :class="{'text-red-500': errors.password_new_confirmation}"
                        >{{ $t('fields.confirmpassword') }}</label>
                        <div class="mt-1">
                            <Field type="password" v-model="form.password_new_confirmation"
                                   name="password_new_confirmation"
                                   class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent"
                                   :class="{'error': errors.password_new_confirmation}"
                            />
                        </div>
                    </div>

                    <div class="sm:flex text-default">
                        <div class="w-full mt-5 relative">
                            <button type="submit"
                                    class="w-full inline-flex justify-center items-center mt-3 px-6 py-3 btn btn-primary text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150"
                            >
                                {{ $t('auth.passwordreset.button.submit') }}
                                <svg v-if="saving" class="animate-spin ml-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                                     fill="none" viewBox="0 0 24 24"
                                >
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                            stroke-width="4"
                                    ></circle>
                                    <path class="opacity-75" fill="currentColor"
                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import {
    Field,
    Form,
} from 'vee-validate';
import * as yup from "yup";
import * as _api from '../lib/api';
import * as _notification from '../lib/notification';

export default {
    name: 'user.myaccount.update-password',
    components: {
        Field,
        Form,
    },
    data () {
        const schema = yup.object().shape({
            password_current: yup.string().max(255).required(),
            password_new: yup.string().max(255).required(),
            password_new_confirmation: yup.string().required().oneOf([yup.ref('password_new'), null], 'Passwords must match'),
        });

        return {
            schema,
            saving: false,
            errors: {},
            alert: {
                show: false,
                message: '',
                class: '',
            },
            form: {
                password_current: '12345678',
                password_new: '12345678',
                password_new_confirmation: '12345678',
            },
        };
    },
    mounted () {
        this.getCustomer();
    },

    methods: {
        async getCustomer () {
            //! needs testing
            const customer = await _api.get('customer', {}, false);

            if (!customer) {
                return;
            }

            return customer;
        },
        async onSubmit (data) {
            this.saving = true;

            const response = await _api.post('customer/password', data, {}, false);

            if (response.result === 'success') {
                // todo: notification

                _notification.set('notification.auth.password-updated', 'success');

                this.$router.push({name: 'user.myaccount'});
            } else {
                this.errors = response.errors;
                _notification.set('not saved', 'error');
            }
            this.saving = false;
        },
    },
};
</script>
